<script>
export default {
  name: "Page"
}
</script>

<template>
    <div class="Page">
      <slot></slot>
    </div>
</template>

<style scoped>
  .Page{
    display: flex;
    flex-direction: column;
    height: calc(100% - 50px)
  }
</style>
<template>
  <div class="page_search">
      <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'PageSearch'
}
</script>
<style>
  .page_search{
    padding:10px;
    display: flex;
  }
  .page_search .el-form-item{
    margin-bottom: 0;
  }
</style>
export default [
    {
        path: "/accredit",
        name: 'Accredit',
        meta: {
            title: '授信审批',
            index:'1-1/1',
            isMenu:true,
            fatherPath:'/dashboard',
            keepAlive:true,
        },
        component: () => import("@/views/client_Page_pc/dashboard/Accredit.vue"),
    },
]
//首页
import Dashboard from "@/router/PC/ActiveRoute/dashboard";
//授信审批
import Accredit from "@/router/PC/ActiveRoute/dashboard/accredit";
//个人中心
import personalCenter from "@/router/PC/ActiveRoute/personal_center";
//推广返利
import PromotionRebate from '@/router/PC/ActiveRoute/Promotion_rebate'
export default [
    {
        path: "/client",
        name: '/client',
        meta: {
            title: '客户端',
        },
        component: () => import("@/views/client_Page_pc/index.vue"),
        children: [
            ...Dashboard,
            ...personalCenter,
            ...PromotionRebate,
            ...Accredit,
        ]
    }
]
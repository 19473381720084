<template>
  <el-pagination
    :small="small"
    class="app-layout-page-table-button-pagination-all"
    :layout="layout"
    background
    :total="page.tableDataCount"
    :page-size="page.searchParams.page_size"
    :currentPage.sync="page.searchParams.page_number"
    @size-change="doPaginationSizeChange"
    @current-change="doPaginationCurrentChange"
    :pager-count="count"
  ></el-pagination>
</template>

<script>
import { log } from 'mathjs';
export default {
  name: 'PageTableBottomPaginationAll',
  props: {
    page: {
      type: Object,
      default() {
        return {
          search(source) {
            console.log('no search「' + source + '」');
          },
          searchParams: {
            page_size: 10,
            page_number: 1,
          },
          tableDataCount: 0,
        };
      },
    },
    layout: {
      type: String,
      default: 'total,sizes,prev,pager,next,jumper',
    },
    small: {
      type: Boolean,
      default: false,
    },
    count: {
      type: [String, Number],
      default: 7,
    },
  },
  methods: {
    doPaginationSizeChange(val) {
      this.page.searchParams.page_size = val;
      this.page.search('doPaginationSizeChange');
    },
    doPaginationCurrentChange(val) {
      this.page.searchParams.page_number = val;
      this.page.search('doPaginationCurrentChange');
    },
  },
};
</script>

<style>
.app-layout-page-table-button-pagination-all {
  color: #000000 !important;
  font-size: 12px !important;
}
.el-pagination__total {
  color: #000000 !important;
  font-size: 12px !important;
}
.app-layout-page-table-button-pagination-all .el-input__inner {
  color: #000000 !important;
  font-size: 12px !important;
}
.el-pagination__jump {
  color: #000000 !important;
  font-size: 12px !important;
}
</style>

import { INTERFACE_URL } from '../../config';
import Vue from 'vue';
import axios from 'axios';
import store from '@/store';
import utils from '@/utils';
import custom from"./custom";
import router from '../router';

const request=axios.create({
  baseURL:INTERFACE_URL,// Axios的基础URL，表示将要访问的接口的前缀；
  timeout:10000,//设置请求超时时间
})
// Axios的请求数量，用于表示当同时存在多个并发请求的时候，什么时候开始、什么时候结束；
let axiosRequestTotal = 0;
// 定义加载动画对象
let axiosLoading = null;
//当前路由
let ArrUrl=''
//清空数据
function ClearDate(){
    let sessionMoney=utils.CacheSessionGet('moneyloans')
    let CloseAll= store.getters.GetWindowViewClose
    if(CloseAll.length){
        CloseAll.forEach(item=>{
            item.close()
        })
        store.commit('setWindowViewClose', '')
    }
    utils.CacheSessionClear()
    utils.CacheLocalClear()
    utils.cookieRemove('__ERP_IDENTITY_NUMBER__');
    utils.cookieRemove('__ERP_IDENTITY_TOKEN__');
    utils.cookieRemove('__ERP_IDENTITY_TOKEN__KEY__');
    utils.cookieRemove('__ERP_USERNAME__');
    utils.cookieRemove('__ERP_SELLER_USERNAME__');
    utils.cookieRemove('__ERP_SELLER_ID__');
    store.commit('setNickName', '')
    utils.CacheSessionSet('moneyloans',sessionMoney)
}
// 添加请求拦截器
request.interceptors.request.use(
    function (config) {

      // 为所有的接口增加请求编号、账户身份参数
      if (!config.data) {
          config.data = {};
      }
      config.data.reqno = Vue.prototype.$utils.reqno();
        if(!custom.identityHas(config)){
            custom.identitySet(config.data)
        }
      if (axiosRequestTotal === 0) {
          axiosLoading = Vue.prototype.$loading({
              lock: true,
              background: 'rgba(0, 0, 0, 0.7)',
              target: document.querySelector('body'),
              customClass: 'loading-zindex',
          });
      }
      // 请求数量+1
      axiosRequestTotal++;
        ArrUrl = config.url;
      return config;
  },
  function (error) {
      if (ArrUrl != 'v1/api/makafin/heartbeat' && ArrUrl !='v1/api/makafin/base_account/logout') {
          Vue.prototype
              .$alert(error, {
                  type: 'error',
              })
              .then(() => {});
          return Promise.reject(error);
      }
  }
);

// 添加响应拦截器
request.interceptors.response.use(
  function (response) {
      setTimeout(function () {
          if (axiosRequestTotal <= 0) {
              return;
          }
          // 收到数据后、请求数量-1
          axiosRequestTotal--;
          // 请求结束时关闭加载中的效果「一般都会这么做」
          if (axiosLoading != null && axiosRequestTotal === 0) {
              axiosLoading.close();
              // 判断接口返回结果是否是未登录的错误信息
              if (parseInt(response.data.code) === -106) {
                  if(ArrUrl != 'v1/api/makafin/heartbeat' && ArrUrl !='v1/api/makafin/base_account/logout'){
                      Vue.prototype.$message({
                          message:'账号已退出,请重新登录',
                          type:'error',
                          offset:80,
                          customClass:'messageIndex'
                      })
                  }
                  ClearDate()
                  // Vue.prototype.$store.commit('setNickName','')
                  // 未登录时跳转进行账户授权登录
                  if(router.currentRoute.path !=='/home/index'){
                      router.push('/home/index')
                  }
              }
          }
      }, 500);
      return response;
  },
  function (error) {
      if (ArrUrl != 'v1/api/makafin/heartbeat' && ArrUrl !='v1/api/makafin/base_account/logout') {
          Vue.prototype
              .$alert(error, {
                  type: 'error',
              })
              .then(() => {});
          return Promise.reject(error);
      }
    }
);
export default {
    /**
     * GET请求
     * @param api
     * @param callbackSuccess
     * @param callbackError
     * @param callbackComplete
     */
    get(api, callbackSuccess, callbackError, callbackComplete) {
        request.get(api)
            .then(res=>{
                if (res.data.code.toString() === '0') {
                    callbackSuccess && callbackSuccess(res.data.content);
                } else {
                    // 请求结果的标准化处理中间件
                    if (callbackError) {
                        callbackError(res.data);
                    } else {
                        // 错误提示「一般会弹窗提示错误信息」
                        Vue.prototype
                            .$alert(res.data.message, {
                                type: 'error',
                            })
                            .then(() => {});
                    }
                }
                callbackComplete && callbackComplete();
            })
            .catch(err => {
            if (callbackError) {
                callbackError(err);
            } else {
                // 错误提示「一般会弹窗提示错误信息」
                Vue.prototype
                    .$alert(err, {
                        type: 'error',
                    })
                    .then(() => {});
            }
            callbackComplete && callbackComplete();
        });
    },
    /**
     * POST请求
     * @param api
     * @param datas
     * @param callbackSuccess
     * @param callbackError
     * @param callbackComplete
     */
    post(api, datas, callbackSuccess, callbackError, callbackComplete) {
        request.post(api, datas)
            .then(function (res) {
                if (res.data.code.toString() === '0') {
                    callbackSuccess && callbackSuccess(res.data.content);
                } else {
                    // 请求结果的标准化处理中间件
                        if (callbackError) {
                            callbackError(res.data);
                        } else if(res.data.code.toString() !== '-106') {
                            // 错误提示「一般会弹窗提示错误信息」
                            Vue.prototype
                                .$alert(res.data.message, {
                                    type: 'error',
                                })
                                .then(() => {
                                });
                        }
                }
                callbackComplete && callbackComplete();
            })
            .catch(err => {
                if (callbackError) {
                    callbackError(err);
                } else {
                    // 错误提示「一般会弹窗提示错误信息」
                    Vue.prototype
                        .$alert(err, {
                            type: 'error',
                        })
                        .then(() => {});
                }
                callbackComplete && callbackComplete();
            });
    },
};

<template>
  <el-button
      :size="size"
      :type="type"
      @click="search('load')"
      :style="styleButton"
  >
    {{title}}
  </el-button>
</template>

<script>
export default {
  name: 'PageSearchResetButton',
  props:{
    title:{
      type:String,
      default:'重置查询',
    },
    size:{
      type:String,
      default:'small',
    },
    type:{
      type:String,
      default:'',
    },
    styleButton:{
      type:Object,
      default:()=>{
        return {}
      },
    },
    search:{
      type:Function,
      default:()=>{}
    },

  },
  data(){
    return {

    }
  },
  methods:{

  }
}
</script>
<style>

</style>
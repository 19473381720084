import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import utils from './utils';
import api from './api';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'default-passive-events';
import Components from '@/components';
import '@/assets/fonts/iconfont.css';
import  'animate.css';
import "../public/resize";
import {ENV} from "../config";
import {LOG2E} from "mathjs";

Vue.use(ElementUI);

Vue.config.productionTip = false;
Vue.prototype.$utils = utils;
Vue.prototype.$api = api;

[...Components].forEach(v=> {
  Vue.component(v.name, v);
});
router.beforeEach((to, from, next)=>{
  // if(ENV==='publish'){
  //   let url=window.location.href
  //   const pattern = /^https:\/\/www\.makafin\.com/;
  //   if(!pattern.test(url)){
  //     let ind=url.indexOf('makafin.com')+11
  //     url='https://www.makafin.com'+url.slice(ind)
  //   }
  //   window.location.href=url
  // }
  //访问移动端
  if (utils.isMobileDevice()) {
    import('@/media/window.js')
  }else{
    if(to.path==='/manual/detail'){
      utils.CacheSessionSet('manualId',+to.query.id)
      next('/home/instruction_manual')
    }
    if(to.path==='/home/apply' || to.path==='/home/register'){
      next('/home/index')
    }
  }
  if(to.path !=='/home/index'){
    if(to.path ==='/home'){
      next('/home/index')
    }
  }
  next()
})
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');

//cookie数据
export default {
  /**
   * 设置cookie
   * @param name cookie名
   * @param value cookie值
   * @param expHour 过期时间,单位小时
   * @param domain cookie域名
   * @param path 服务器路径
   * */
  cookieSet(name, value, expHour, domain, path) {
    document.cookie =
      name +
      '=' +
      encodeURIComponent(value === undefined ? '' : value) +
      (expHour ? '; expires=' + new Date(new Date().getTime() + (expHour - 0) * 3600000).toUTCString() : '') +
      '; domain=' +
      (domain ? domain : document.domain) +
      '; path=' +
      (path ? path : '/');

    return this;
  },
  /**
   * 获取cookie
   * @param name cookie名
   * */
  cookieGet(name) {
    return document.cookie.match(new RegExp('(^| )' + name + '=([^;]*)(;|$)')) == null ? null : decodeURIComponent(RegExp.$2);
  },
  /**
   * 删除cookie
   * @param name cookie名
   * */
  cookieRemove(name) {
    if (this.cookieGet(name) != null) this.cookieSet(name, null, -1);
    return this;
  },
};

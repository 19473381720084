export default {
    /*
    * returns 复制对象
    * **/
    ObjectToJSON(obj){
        return  JSON.parse(JSON.stringify(obj))
    },
    /*
   * returns 是否是移动端
   * **/
    isMobileDevice(){
        return /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);
    }
};
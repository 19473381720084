export default [
    {
        path: "/dashboard",
        name: 'Dashboard',
        meta: {
            title: '首页',
            index:'1-1',
        },
        component: () => import("@/views/client_Page_pc/dashboard/index.vue"),
    }
]
export default {
    //数字带上分割符号
    PriceFormat(num){
        // 对数据进行初步的筛选
        num = String(Number(num)) !== 'NaN' ? Number(num) : 0;
        if(num === 0) return 0;
        let isNagative = num < 0;
        num = Math.abs(num);
        let ind=0
        if(String(num).indexOf(".") === -1){
            ind=String(num).length
        }else{
            ind=String(num).indexOf(".")
        }
        // 整数部分
        let integerStr = String(num).slice(0,ind)
        // 小数部分
        let pointStr = String(num).slice(ind)
        function addCode(str,startIndex = 0){
            // 如果这个数据整数部分没有超过三位数，直接返回
            if(String(str).length <= 3) return str;

            // 翻转数组
            if(startIndex === 0){
                str = str.split("").reverse().join("")
            }
            // 当前用于操作的字符串的长度
            str = str.slice(0,startIndex+3)+','+str.slice(startIndex+3)

            // 这里加4表示：三个字符加上一个分隔符号
            // 每加一次千分位符号之后，判断剩余的数字的个数（不包含分隔符），并更新截取位点
            // 如 123456（翻转之后的数据） 这个起始截取位点是 0, 处理之后是 123,456, 则下一次截取位点就是4,从4这个数字开始继续下一轮处理
            startIndex = startIndex + 4;

            // 这个是用于下一次操作的数据，长度不超过3的时候也是直接返回，否则就继续回调
            let surplusStr = str.slice(startIndex)
            if(surplusStr.length > 3){
                return addCode(str, startIndex)
            }
            // 返回数据，将数据的顺序回正  123,456 变成 654,321
            return str.split("").reverse().join("")
        }
        // 将整数部分与小数部分拼接起来
        let result = addCode(integerStr)+pointStr;
        // 是负数的加上符号
        return isNagative ? ('-'+result) : result;
    },
}
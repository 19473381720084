<script>
export default {
  name: "PageFooter"
}
</script>

<template>
  <div class="page_footer">
    <slot></slot>
  </div>
</template>

<style scoped>
.page_footer{
  text-align: center;
  padding:10px 0;
  border-top:1px solid rgba(128,128,128,0.5) ;
}
</style>
/**
 * 接口URL
 * @type {string}
 */
export const INTERFACE_URL = '//www.makafin.com/';
// https://www.makafin.com/
/**
 * Erp路径
 * @type {string}
 */
export const  ERP_URL= 'https://seaway.szzhihuiyun.com/';
/**
 * 上传图片URL
 * @type {string}
 */
export const UPLOAD_IMAGE_URL = 'https://seaway-image.szzhihuiyun.com/';


/**
 * 环境变量
 * @type {string}
 */
export const ENV = 'publish';

import cookie from '@/utils/cookie';
import cache from '@/utils/cache';
import { calculate } from '@/utils/math';
import dayjs from "@/utils/dayjs";
import reqno from "@/utils/reqno";
import object from "@/utils/object";
import text from "@/utils/text";
import format from "@/utils/format";
export default {
  ...cookie,
  ...cache,
  calculate,
  dayjs,
  ...reqno,
  ...object,
  ...text,
  ...format,
};

<script>
export default {
  name: "PageTableContain"
}
</script>

<template>
    <div class="PageTableContain">
      <div class="PageTableInnerContain">
        <slot></slot>
      </div>
    </div>
</template>

<style scoped>
    .PageTableContain{
      flex:1;
      padding:10px;
      background: #e3e3e3;
    }
    .PageTableInnerContain{
      margin: 5px;
      height: 100%;
      background: white;
    }
</style>
//数据存储处理
const sessionStorage = window.sessionStorage;
const localStorage = window.localStorage;
export default {
  //TODO 获取session数据
  CacheSessionGet(key) {
    try {
      return JSON.parse(sessionStorage.getItem(key));
    } catch (err) {
      return false;
    }
  },
  //TODO 存储session数据
  CacheSessionSet(key, val) {
    sessionStorage.setItem(key, JSON.stringify(val));
  },
  //TODO 删除session数据
  CacheSessionRemove(key) {
    sessionStorage.removeItem(key);
  },
  //TODO 清空session数据
  CacheSessionClear() {
    sessionStorage.clear();
  },
  //TODO 获取local数据
  CacheLocalGet(key) {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (err) {
      return false;
    }
  },
  //TODO 设置local数据
  CacheLocalSet(key, val) {
    localStorage.setItem(key, JSON.stringify(val));
  },
  //TODO 删除local数据
  CacheLocalRemove(key) {
    localStorage.removeItem(key);
  },
  //TODO 清空local数据
  CacheLocalClear() {
    localStorage.clear();
  },
};

export default [
    {
        path: "/home",
        name: 'Home',
        meta: {
        },
        component: () => import("@/views/client_home_mobile/index.vue"),
        children:[
            {
                path: "/home/index",
                name: 'HomeIndex',
                meta: {
                    KeepAlive:true,
                    title:'首页',
                },
                component: () => import("@/views/client_home_mobile/Home.vue"),
            },
            {
                path: "/home/instruction_manual",
                name: 'InstructionManual',
                meta: {
                    KeepAlive:true,
                    title:'使用手册',
                },
                component: () => import("@/views/client_home_mobile/InstructionManual.vue"),
            },
            {
                path: "/home/maka_club",
                name: 'MakaClub',
                meta: {
                    KeepAlive:true,
                    title:'麦嘉俱乐部',
                },
                component: () => import("@/views/client_home_mobile/MakaClub.vue"),
            },
            {
                path: "/home/about_us",
                name: 'AboutUs',
                meta: {
                    KeepAlive:true,
                    title:'关于我们',
                },
                component: () => import("@/views/client_home_mobile/AboutUs.vue"),
            },
            {
                path: "/home/apply",
                name: 'Apply',
                meta: {
                    title:'申请',
                },
                component: () => import("@/views/client_home_mobile/Apply.vue"),
            },
            {
                path: "/home/register",
                name: 'Register',
                meta: {
                    title:'注册',
                },
                component: () => import("@/views/client_home_mobile/Register.vue"),
            },
            {
                path: "/manual/detail",
                name: 'ManualDetail',
                meta: {
                    title:'手册详情',
                },
                component: () => import("@/views/client_home_mobile/ManualDetail.vue"),
            }
        ]
    }
]
<template>
    <div class="app-layout-page-table-button">
        <div class="left">
            <slot name="left"></slot>
        </div>
        <div class="right">
            <slot name="right"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PageTableBottom'
    }
</script>

<style>
    .app-layout-page-table-button {
        overflow: hidden;
		padding: 10px 10px;
		background: #FFFFFF;
    }

    .app-layout-page-table-button > .left {
        float: left;
    }

    .app-layout-page-table-button > .right {
        float: right;
    }
</style>

<template>
  <div id="demo1"></div>
</template>

<script>
import wangEditor from 'wangeditor';
import { INTERFACE_URL } from '../../../config';
import emojiJSON from './emoji.json';
export default {
  name: 'RichText',
  props: {
    // v-model
    value: {
      type: [Number, String],
      default: '',
      val: '',
    },
    height: {
      type: [Number, String],
      default: 200,
    },
    type: {
      type: [Number, String],
      default: '1',
    },
  },
  watch: {
    value(val) {
      if (this.aa && val != '') {
        this.editor.txt.html(val);
        this.aa = false;
      }
    },
    input(val) {
      this.$emit('input', val);
    },
  },
  data() {
    return {
      editor: '',
      editorContent: '',
      input: '',
      emojiList: emojiJSON,
      isChange: false,
      aa: true,
    };
  },
  mounted() {
    this.editorsFun();
    this.editor.txt.html(this.value);
  },
  methods: {
    editorsFun() {
      this.editor = new wangEditor(`#demo1`);
      this.editor.config.height = this.height;

      this.editor.config.zIndex = 1;
      if (this.type == 1) {
        let toConfigure = ['Redo', 'Code', 'Todo'];
        toConfigure.forEach(item => {
          this.editor.config.menus.splice(this.editor.config.menus.indexOf(item), 1);
        });
      } else {
        this.editor.config.menus = [];
        this.editor.config.showFullScreen = false;
      }

      this.editor.config.uploadImgServer =
        INTERFACE_URL +
        'v1/api/erp/upload/wangeditor/images' +
        '?reqno=' +
        this.$utils.reqno();
      this.editor.config.uploadFileName = 'file';
      this.editor.config.uploadImgMaxSize = 8 * 1024 * 1024;
      this.editor.config.uploadImgTimeout = 3 * 60 * 1000;
      this.editor.config.onchange = newHtml => {
        this.input = newHtml;
      };
      this.editor.config.emotions = [
        {
          title: '默认',
          type: 'image',
          content: this.emojiList,
        },
      ];
      this.editor.create();
    },
  },
  beforeDestroy() {
    // 销毁编辑器
    this.editor.destroy();
    this.editor = null;
  },
};
</script>

<style></style>

export default [
    {
        path: "/Promotion_rebate",
        name: 'PromotionRebate',
        meta: {
            title: '推广返利',

        },
        component: () => import("@/views/client_Page_pc/Promotion_rebate/index.vue"),
    }
]
import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

export default new Vuex.Store({
  state:{
    NickName:'',
    WindowViewClose:[],
  },
  mutations:{
    setNickName(state,nickName){
      state.NickName = nickName;
    },
    setWindowViewClose(state,item){
      if(item){
        state.WindowViewClose.push(item)
      }else{
        state.WindowViewClose=[]
      }
    },
  },
  getters:{
    GetNickName:(state)=>{
      return state.NickName
    },
    GetWindowViewClose:(state)=>{
         return state.WindowViewClose
    }
  },
  actions: {},
  modules: {},
});

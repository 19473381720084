<template>
  <div id="app">
    <transition name="el-fade-in">
      <router-view />
    </transition>
  </div>
</template>
<script>
import {ENV} from "../config";

export default {
  name: 'App',
  methods:{

  },
  beforeCreate() {
    if(ENV==='publish'){
      let url=''
      let host=window.location.origin
      if(host !=='https://www.makafin.com'){
        url='https://www.makafin.com/'+window.location.hash
        window.location.href=url
      }
    //   let url=window.location.href
    // console.dir(window.location)
    //   const pattern = /^https:\/\/www\.makafin\.com/;
    //   if(!pattern.test(url)){
    //     let ind=url.indexOf('makafin.com')+11
    //     console.log(url.slice(ind))
    //     url='https://www.makafin.com'+url.slice(ind)
    //   }
    //   console.log(url)
    }
  }
}
</script>
<style>
    #app{
      width: 100%;
      height: 100%;
    }
    html,body{
      width: 100%;
      height: 100%;
    }
    .el-button{
      border: 0!important;
    }
    .el-form-item{
      margin-bottom: 1.375rem!important;
    }
    .main_btn1.el-button:hover,.main_btn1.el-button:focus{
      background: linear-gradient(90deg, #ffbe58 0%, #FFCA7D 100%);
      color:#fff;
    }
    .main_btn1.el-button:active{
        background: linear-gradient(90deg, #FBB445 0%, #FB782A 100%);
        color:#fff;
      }
    .main_btn2.el-button:hover,.main_btn2.el-button:focus{
      background: linear-gradient(90deg, #ffbe58 0%, #FFCA7D 100%);
      color:#000;
    }
    .main_btn2.el-button:active{
      background: linear-gradient(90deg, #FBB445 0%, #FB782A 100%);
      color:#000;
    }
    .main_btn3.el-button:hover,.main_btn3.el-button:focus{
      background: #FFCA7D;
      color:#fff;
    }
    .main_btn3.el-button:active{
      background: #F8B14A;
      color:#fff;
    }
    .main_btn4.el-button:hover,.main_btn4.el-button:focus{
      background: #FFCA7D;
      color:#000;
    }
    .main_btn4.el-button:active{
      background: #F8B14A;
      color:#000;
    }
    .main_btn5.el-button:hover,.main_btn5.el-button:focus{
      background: #FFA873;
      color:#FFF;
    }
    .main_btn5.el-button:active{
      background: #FA8C48;
      color:#FFF;
    }
    .main_btn6.el-button:hover,.main_btn6.el-button:focus{
      background: #45D103;
      color:#FFF;
    }
    .main_btn6.el-button:active{
      background: #37AB01;
      color:#FFF;
    }
    .main_btn7.el-button:hover,.main_btn7.el-button:focus{
      background: #DC4949;
      color:#FFF;
    }
    .main_btn7.el-button:active{
      background: #B50808;
      color:#FFF;
    }
</style>
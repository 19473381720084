<template>
  <el-button
    :size="size"
    :type="type"
    @click="search('submit')"
    :style="styleButton"
  >
    {{title}}
  </el-button>
</template>

<script>
export default {
  name: 'PageSearchButton',
  props:{
    title:{
      type:String,
      default:'查询',
    },
    size:{
      type:String,
      default:'small',
    },
    type:{
      type:String,
      default:'primary',
    },
    styleButton:{
      type:Object,
      default:()=>{
        return {}
      },
    },
    search:{
      type:Function,
      default:()=>{}
    },

  },
  data(){
    return {

    }
  },
  methods:{

  }
}
</script>
<style>

</style>
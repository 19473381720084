export default [
    {
        path: "/home",
        name: 'Home',
        meta: {
            title:'首页',
        },
        component: () => import("@/views/client_home_pc/index.vue"),
        children:[
            {
                path: "/home/index",
                name: 'HomeIndex',
                meta: {
                    title:'首页',
                },
                component: () => import("@/views/client_home_pc/Home.vue"),
            },
            {
                path: "/home/instruction_manual",
                name: 'InstructionManual',
                meta: {
                    title:'使用手册',
                },
                component: () => import("@/views/client_home_pc/InstructionManual.vue"),
            },
            // {
            //     path: "/home/maka_club",
            //     name: 'MakaClub',
            //     meta: {
            //         title:'麦嘉俱乐部',
            //     },
            //     component: () => import("@/views/client_home_pc/MakaClub.vue"),
            // },
            {
                path: "/home/about_us",
                name: 'AboutUs',
                meta: {
                    title:'关于我们',
                },
                component: () => import("@/views/client_home_pc/AboutUs.vue"),
            },
        ]
    }
]
<template>
  <div class="upload_image">
    <el-upload
        class="upload-demo"
        :action="action"
         multiple
        :headers="headers"
        :data="{
          name: 'file',
          reqno: this.$utils.reqno(),
          erp_identity_number:parseInt($utils.cookieGet('__ERP_IDENTITY_NUMBER__')),
          erp_identity_token : $utils.cookieGet('__ERP_IDENTITY_TOKEN__'),
          erp_identity_token_key :$utils.cookieGet('__ERP_IDENTITY_TOKEN__KEY__'),
          }"
        :file-list="fileList"
        :before-upload="BeforeUnload"
        :on-success="uploadSuccess"
        :before-remove="beforeRemove"
        :on-remove="handleRemove"
        :accept="'image/jpeg,image/png,.pdf'"
    >
      <el-button style="width: 10.25rem;background: #EAEAEA;color: #333;font-size: 1rem"><i class="el-icon-upload2"></i>选择文件</el-button>
      <div slot="tip" class="el-upload__tip" style="font-size: 0.875rem">支持PDF、JPG、PNG格式，可一次性上传多个文件,大小超过10MB</div>
    </el-upload>
  </div>
</template>

<script>
import {INTERFACE_URL} from "../../../config/index"
export default {
  name: 'UploadDoc',
  props: {
    value:{
      type:Array,
      default:()=>{return []}
    },
    // src:{
    //   type:String,
    //   default:''
    // },
    // //上传类名
    // resetName:{
    //   type:String,
    //   default:''
    // }
  },
  data() {
    return {
      headers: {
        'api-ver': '1.0.0',
        token:'',
        charset: 'UTF-8',
      },
      action:INTERFACE_URL+'v1/api/makafin/upload/image',
      IMGList:[],
    }
  },
  computed:{
    fileList:function (){
      if(this.IMGList.length){
        return this.IMGList.map(item=>{
          return {
            name:item.name,
            url:item.image,
          }
        })
      }else{
        return []
      }
    }
  },
  mounted() {
    this.IMGList=this.value
  },
  methods: {
    // //判断选中文件个数
    // handleExceed(files, fileList) {
    //   this.$message.warning(`当前限制选择 2 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    // },
    //是否移除
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${ file.name }？`);
    },
    //上传前
    BeforeUnload(file){
      // 图片格式是否正确
      let bool = null;
      // 获取图片的后缀名
      const dotIndex = file.name.lastIndexOf('.');
      const suffixOfImage = file.name.slice(dotIndex + 1).toLowerCase();
      switch (suffixOfImage) {
        case 'png':
        case 'jpg':
        case 'pdf':
          bool = true;
          break;
        default:
          bool = false;
      }
      if (bool) {
        return true;
      } else {
        this.$message({
          type: 'warning',
          message: '您上传的图片格式不正确!',
          offset: 50,
        });
        return false;
      }
    },
    //上传成功回调
    uploadSuccess(response, file, fileList){
      if(response.code===0){
       if(fileList.every(item => item.status === "success")){
        fileList.forEach(item=>{
           if(item.response && item.response.code===0){
               this.IMGList.push(item.response.content)
           }
         })
       }
      }
    },
    // //重新上传
    // reUploadImg(){
    //   this.IMGList.image=''
    //   this.IMGList.id=''
    //   let fatherDOM=document.querySelector('.'+this.resetName)
    //   this.$nextTick(()=>{
    //     fatherDOM.querySelector('.el-upload__input').click()
    //   })
    // },
    //删除图片
    handleRemove(file, fileList) {
      let ind=-1
      if(file.url){
        ind=this.IMGList.findIndex(item=>item.image===file.url)
      }else{
        ind=this.IMGList.findIndex(item=>item.image===file.response.content)
      }
      this.IMGList.splice(ind,1)
    },
  }
};
</script>
<style scoped>
.upload_image{
 width: 100%;
}
.upload_image .image{
  width: 150px;
  height: 150px;
  border: 1px solid #e6e6e6;
}
.img_os{
  font-size: 14px;
  position: absolute;
  bottom: 0;
  width: 152px;
  height: 30px;
  background: white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
</style>

import Page from "@/components/Page/Page.vue";
import PageFooter from "@/components/Page/PageFooter.vue";
import PageSearch from "@/components/page-search/PageSearch.vue";
import PageSearchButton from "@/components/page-search/PageSearchButton.vue";
import PageSearchResetButton from "@/components/page-search/PageSearchResetButton.vue";
import PageTableContain from "@/components/page-table-contain/PageTableContain.vue";
import PageTableBottom from "@/components/page-table-bottom/PageTableBottom.vue";
import pageTableBottomPaginationAll from "@/components/page-table-bottom/PageTableBottomPaginationAll.vue";
import RichText from "@/components/text/RichText.vue";
import UploadDoc from "@/components/picture/UploadDoc.vue";
export default [
    Page,
    PageFooter,
    PageSearch,
    PageSearchButton,
    PageSearchResetButton,
    PageTableContain,
    PageTableBottom,
    pageTableBottomPaginationAll,
    RichText,
    UploadDoc,
];

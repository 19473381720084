import Vue from 'vue';
//pc端
import PCLogin from '@/router/PC/StaticPage.js';
//移动端
import MobileLogin from "@/router/Mobile/StaticPage"
//客户端用户系统
import PCClient from '@/router/PC/ActiveRoute/index'
import VueRouter from "vue-router";

Vue.use(VueRouter);
// 解决报错 此方法只针对VueRouter3.0以上
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
 function isMobileDevice(){
  return /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);
}

let routes=[]
  if(isMobileDevice()){
    routes = [
      {
        path: '/',
        redirect: '/home/index',
      },
      ...MobileLogin,
    ];
  }else{
     routes = [
      {
        path: '/',
        redirect: '/home/index',
      },
      ...PCLogin,
      ...PCClient,
    ];
  }
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

export default router;

(function (doc, win) {
    if((typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('Mobile') !== -1)){
        return;
    }
    var docEl = doc.documentElement,
        resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
        recalc = function () {
            var clientWidth = docEl.clientWidth;
            if (!clientWidth) return;
            /*设置一个屏幕最大宽度临界值，当大于这个宽度时，字体也不放大了*/
            // if(clientWidth>1900){
            //     docEl.style.fontSize = '16px';
            //     return;
            // }
            // if(clientWidth<960){
            //     docEl.style.fontSize = '12px';
            //     return;
            // }
                docEl.style.fontSize = 16*(clientWidth/1920)+'px';
            // if(clientWidth<960){
            //     docEl.style.fontSize = '12px';
            // }else if(clientWidth<1300){
            //     docEl.style.fontSize = '12px';
            // }else if(clientWidth<1900){
            //     docEl.style.fontSize = '14px';
            // }else {
            //     docEl.style.fontSize = '16px';
            // }

        };

    if (!doc.addEventListener) return;
    win.addEventListener(resizeEvt, recalc, false);
    doc.addEventListener('DOMContentLoaded', recalc, false);
})(document, window);
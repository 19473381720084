import Vue from 'vue';
export default {
    /**
     * 获取身份Number
     * @returns {string}
     */
    identityNumber() {
        return Vue.prototype.$utils.cookieGet('__ERP_IDENTITY_NUMBER__') ||'';
    },
    /**
     * 获取身份Token
     * @returns {string}
     */
    identityToken() {
        return Vue.prototype.$utils.cookieGet('__ERP_IDENTITY_TOKEN__') || '';
    },
    /**
     * 获取身份Token的Key
     * @returns {string}
     */
    identityTokenKey() {
        return Vue.prototype.$utils.cookieGet('__ERP_IDENTITY_TOKEN__KEY__') || '';
    },
    /**
     * 请求数据的身份处理
     * @param {object} data 请求数据
     * @returns {*}
     */
    identitySet(data) {
        data.erp_identity_number = parseInt(this.identityNumber());
        data.erp_identity_token = this.identityToken();
        data.erp_identity_token_key = this.identityTokenKey();
        return data;
    },
    /**
     * 判断身份是否存在
     * @param {object} config 请求数据
     * @returns {boolean}
     */
    identityHas(config) {
        // 不需要进行身份验证的接口配置
        const condition = [
            'v1/api/makafin/base_account/login/vcode',
            'v1/api/makafin/base_account/registration',
            'v1/api/makafin/base_account/send/sms',
            '/v1/api/makafin/index',
            'v1/api/makafin/banner/list',
        ];
        return condition.includes(config.url);
    },
}
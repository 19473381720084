//文本操作
export default {
    /**
     * 复制文本内容
     * @param text 要复制的文本
     * @param Message 复制状态信息提示==传this.$message
     */
    copyString(text,Message) {
        if (!navigator.clipboard && window.isSecureContext) {
            return navigator.clipboard.writeText(text);
        } else {
            // 判断是否支持拷贝
            if (!document.execCommand('copy')) return Promise.reject();
            // 创建标签，并隐藏
            const textArea = document.createElement('textarea');
            textArea.style.position = 'fixed';
            textArea.style.top = textArea.style.left = '-100vh';
            textArea.style.opacity = '0';
            textArea.value = text;
            document.body.appendChild(textArea);
            // 聚焦、复制
            textArea.focus();
            textArea.select();
            return new Promise((resolve, reject) => {
                // document.execCommand('copy') ? resolve() : reject();
                if(document.execCommand('copy')){
                    Message({
                        type: 'success',
                        message: '复制成功',
                        offset: 50,
                        customClass: 'messageIndex',
                        duration:1000
                    });
                    resolve()
                }else{
                    Message({
                        type: 'error',
                        message: '复制失败',
                        customClass: 'messageIndex',
                        offset: 50,
                        duration:1000
                    });
                    reject()
                }
                textArea.remove();
            });
        }
    },
    /**
     * 路径下载
     * @param url 路径
     */
    Download(url) {
        let image = new Image();
        image.src = url;
        // 解决跨域 Canvas 污染问题
        image.setAttribute("crossOrigin", "Anonymous"); // 支持跨域
        image.onload = function () {
            var canvas = document.createElement("canvas");
            canvas.width = image.width;
            canvas.height = image.height;
            var context = canvas.getContext("2d");
            context.drawImage(image, 0, 0, image.width, image.height);
            var imageurl = canvas.toDataURL("image/png"); //得到图片的base64编码数据
            var a = document.createElement("a"); // 生成一个a元素
            var event = new MouseEvent("click"); // 创建一个单击事件
            a.download = url.slice(url.lastIndexOf('/')+1) || "photo"; // 设置图片名称
            a.href = imageurl; // 将生成的URL设置为a.href属性
            a.dispatchEvent(event); // 触发a的单击事件
        }
    },
};
